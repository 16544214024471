<template>
  <div class="container">
    <div v-if="showContinuePlaceHolder">
      <continueWatchingPlaceholder></continueWatchingPlaceholder>
    </div>
    <div
      v-else-if="
        !showContinuePlaceHolder &&
          continueWatchData &&
          continueWatchData.length > 0
      "
    >
      <section>
        <!--slider-buttons-div-->
        <div
          class="slider-header"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        >
          <div class="slider-title">
            <span class="title">{{ $t("Continue Watching") }}</span>
         </div>
        <div class="slider-head">
            <span
             v-if="continueWatchData.length >= 6"
              class="seeall"
              @click="seeAll()"
              >{{ $t("see all") }}</span
            >
            <div class="slider-head-btns">
              <div
                id="continueWatch-controls"
                class="slider-controls"
                :dir="localDisplayLang === 'ara' ? 'ltr' : ''"
              >
                <!-- previous-arrow -->
                <button type="button">
                  <img
                    src="@/assets/icons/less-than-filled-50.png"
                    width="30px"
                    alt="less"
                  />
                </button>
                <!-- next-arrow -->
                <button type="button">
                  <img
                    src="@/assets/icons/more-than-filled-50.png"
                    width="30px"
                    alt="more"
                  />
                </button>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div
          class="continueWatch-slider-content"
          id="continueWatch-carousel"
          :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
        >
          <div
            v-for="(item, index) in continueWatchData"
            :key="index"
            class="categorised-continue-contents"
          >
            <div class="continueWatch-box">
              <div class="card-contain">
                <continueMerge
                  :content="item"
                  :screen="screen"
                  :playerInstance="playerInstance"
                  @removeFromContinueData="removeDataFromFire"
                  :isContinueWatch="true"
                />
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import FirebaseActions from "@/mixins/FirebaseActions";
import { store } from "@/store/store";
import {
  vLivePlayer,
  vLivePlaylistItem,
} from "/src/vue-player/vLiveWebPlayer.js";

export default {
  props: {
    screen: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
    // continueFire: {
    //   type: Array
    // }
  },
  data() {
    return {
      // showSeeAll: null,
      continueWatchContent: [],
      continueWatchData: [],
      slider: null,
      showContinuePlaceHolder: false,
      detailPlaylist: [],
      finalPlaylist: [],
      availabilities: [],
      subscriptions: [],
      purchases: [],
      contentProgress: null,
      completeEpisodeListing: [],
      playlistIndex: 0,
      continueData: [],
      page: 1,
      isMpegRequired: false,
      totalFirebaseResponse: null,
    };
  },
  watch: {
    //subscriptions list response
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    //purchase list response
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
    localDisplayLang(val) {
      if (val) {
        if (this.slider) {
          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "profileid",
      "getRtl",
    ]),
    fetchCardType() {
      return {
        cardName: "Slider",
        type: "LANDSCAPE",
        height: "147px",
        width: "261px",
        playButton: { enablePlay: false },
      };
    },
  },
  created() {
    //assigning subscription list response.
    this.isMpegRequired = this.checkMpegRequired();
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.showContinuePlaceHolder = true;
      this.slider = null;
      this.checkEpisodeContinueWatch();

      setTimeout(() => {
        if (
          this.localDisplayLang === "ara" &&
          this.continueWatchData &&
          this.continueWatchData.length < 5
        ) {
          console.log(
            "continue carousel",
            document.getElementById("continueWatch-carousel-iw")
          );
          document.getElementById("continueWatch-carousel-iw")
            ? (document.getElementById(
                "continueWatch-carousel-iw"
              ).style.textAlign = "-webkit-right")
            : "";
        }
      }, 700);
    });
  },
  updated() {
    setTimeout(() => {
      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {
        console.log(
          "continue carousel",
          document.getElementById("continueWatch-carousel-iw")
        );
        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  mounted() {
    //To get the availability list.
    setTimeout(() => {
      if (this.availabilityList !== undefined) {
        this.availabilities = this.availabilityList;
      }
    }, 2000);
    eventBus.$on("removeFromContinueWatch", (data) => {
      console.log("REACHED IN CONTINUE SLIDE FOR REMOVAL", data);
      this.removeDataFromFire(data);
    });

    //To fetch continue watching on app launch.
    this.checkEpisodeContinueWatch();

    //To check for newly added firebase data.
    eventBus.$on("check-new-fire-data", () => {
      this.showContinuePlaceHolder = true;
      this.slider = null;
      this.checkEpisodeContinueWatch();
    });

    eventBus.$on("check-for-series-route", () => {
      this.checkEpisodeContinueWatch();
    });
  },
  methods: {
    ...mapMutations(["setContinueWatchData"]),
    ...mapActions(["listContent"]),
    seeAll() {

    let currentLanguage = this.getCurrentLanguageUrlBase();

      console.log("SEE ALL FOR CONTIUE WATCH================");
      // let section = "continue-watch"
      this.$router.push({
        name: "seeAllContinueWatch",
        params: { continueWatchData: this.totalFirebaseResponse, lang: currentLanguage },
      });
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    attachFirebaseNodes(content) {
      let index = this.continueData.findIndex((el) => {
        return el.objectid === content.objectid;
      });

      if (index > -1) {
        content.firebaseKeys = this.continueData[index];
      }
    },

    constructSlider() {
      if (this.continueWatchData && this.continueWatchData.length > 0) {
        let data = {
          container: "#continueWatch-carousel",
          controlsContainer: "#continueWatch-controls",
          nav: false,
          loop: false,
          slideBy: "page",
          // autoWidth: true,
          gutter: 5,
          items: 5,
          responsive: {
            360: {
              items: 2,
              edgePadding: 5,
              gutter: 5,
            },
            576: {
              edgePadding: 5,
              gutter: 5,
              items: 2,
            },
            768: {
              items: 3,
              edgePadding: 5,
              gutter: 15,
            },
            900: {
              edgePadding: 5,
              gutter: 15,
              items: 4,
            },
            1024: {
              edgePadding: 5,
              gutter: 15,
              items: 4,
            },
            1200: {
              edgePadding: 5,
              gutter: 15,
              items: 4,
            },
            1280: {
              edgePadding: 5,
              gutter: 15,
              items: 4,
            },
            1366: {
              edgePadding: 5,
              gutter: 15,
              items: 5,
            },
            1440: {
              edgePadding: 5,
              gutter: 15,
              items: 5,
            },
            1600: {
              edgePadding: 5,
              gutter: 15,
              items: 5,
            },
            1680: {
              edgePadding: 5,
              gutter: 15,
              items: 6,
            },
            1920: {
              edgePadding: 5,
              gutter: 15,
              items: 6,
            },
            2200: {
              edgePadding: 5,
              gutter: 15,
              items: 7,
            },
            2400: {
              edgePadding: 5,
              gutter: 15,
              items: 8,
            },
          },
        };
        this.$nextTick(() => {
          if (!document.getElementById("continueWatch-carousel")) return;
          this.slider = tns(data);

          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        });
      }
    },
    removeDataFromFire(data) {
      console.log("REMOVE DATA FROM FIRE === ", data);
      // this.showContinuePlaceHolder = true;
      // this.continueWatchData = [];
      // this.continueWatchContent = [];
      this.showContinuePlaceHolder = true;
      this.removeFromContinueWatch(data);
      // this.slider = null;
      setTimeout(() => {
        this.checkEpisodeContinueWatch();
      }, 1000);

      console.log("THIS IS THE REMOVE FUNC", data);
    },
    //checking episode continue watch for a particular series in continue watch
    checkEpisodeContinueWatch() {
      this.continueWatchContent = [];
      this.continueWatchData = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      this.completeEpisodeListing = [];
      this.continueData = [];
      let path = "",
        response = null,
        episodeObjs = null,
        newEpisodeObj = null,
        segEpisodeArr = null,
        movieObjs = null;

      if (this.subscriberid || this.profileid) {
        path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

        firebase
          .database()
          .ref(path)
          .once("value")
          .then((snapshot) => {
            response = this.collectionsToArray(snapshot.val());
            //this.totalFirebaseResponse = response

            // Filter the movies for continue watch
          console.log('RESPONSE IS == ',response)
            movieObjs = response.filter((element) => {
              return (
                element.category === "MOVIE" &&
                element.status === "INPROGRESS" &&
                element.watchedduration
              );
            });

            console.log("ONLY MOVIE ARRAY ->", movieObjs);

            // Filter only the series with episodes key
            episodeObjs = response.filter((element) => {
              return element.episodes;
            });

            // Convert the collection to an array
            newEpisodeObj = [...this.collectionsToArray(episodeObjs)];

            // Create an array of only the episodes of the particular series
            newEpisodeObj.forEach((element) => {
              segEpisodeArr = this.collectionsToArray(element.episodes);
              //assign series title to each episode
              segEpisodeArr.forEach((el) => {
                el.title = element.title;
              });

              // Sort episodes based on timestamp
              segEpisodeArr = segEpisodeArr.sort((a, b) => {
                return b.updatedat - a.updatedat;
              });

              // Pick the first index after sorting as it will be the latest episode watched
              this.continueWatchContent.push(segEpisodeArr[0]);
            });

            this.continueWatchDataTemp = [
              ...this.continueWatchContent,
              ...movieObjs,
            ];

            console.log("THE TEMP DATA - - ", this.continueWatchDataTemp);//25
            this.continueWatchDataTemp = this.continueWatchDataTemp.filter(
              (item) => {
                return item.status ==="INPROGRESS" && item.watchedduration;
              }
            );
            this.continueWatchDataTemp = this.continueWatchDataTemp.sort(
              (a, b) => {
                return b.updatedat - a.updatedat;
              }
            );
            this.totalFirebaseResponse = this.continueWatchDataTemp;
            console.log('The total firebase ressponse==============',this.totalFirebaseResponse)
            // this.setContinueWatchData(this.continueWatchDataTemp)
            console.log("continue watch data temp final before", this.continueWatchDataTemp);
            this.continueWatchDataTemp =
              this.continueWatchDataTemp.length > 15
                ? this.continueWatchDataTemp.slice(0, 15)
                : this.continueWatchDataTemp;
               console.log("continue watch data temp final", this.continueWatchDataTemp); 
								try{
										this.checkLanguage();
									}
								catch(error){
								console.log(error);
								}

            // this.continueWatchData = this.continueWatchData.filter((el) => {
            //   return el.status === "INPROGRESS";
            // });

            // this.continueData = this.continueWatchData.sort((a, b) => {
            //   return b.updatedat - a.updatedat;
            // });

            // this.checkLanguage();

            // console.log("THE FIREBASE CONTINUE WATCH DATA ->", this.continueWatchData);

            // if (this.continueWatchData.length === 0) {
            //   this.showContinuePlaceHolder = false;
            // } else {
            //   this.showContinuePlaceHolder = false;
            //   this.constructSlider();
            // }
          });
      }
    },

    checkLanguage() {
      console.log("CHECK LANGUAGE FUNC CALLED",this.continueWatchDataTemp);

      const seriesObjIds = [];
      const moviesObjIds = [];

      console.log("THE LOCAL LANG", this.localDisplayLang);
      this.continueWatchDataTemp.forEach((element) => {
        console.log("THE ELEMENTS LANG", element.displaylanguage);

        if (element.category == "TVSHOW") {
          seriesObjIds.push(`"${element.objectid}"`);
        } else if (element.category == "MOVIE") {
          moviesObjIds.push(`"${element.objectid}"`);
        }
      });

      console.log("THE LENGTH OF SERIES-", seriesObjIds);

      console.log("THE LENGTH OF MOVIES-", moviesObjIds);

      let seriesPromise;
      let moviesPromise;

      if (seriesObjIds && seriesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          displaylanguage: this.localDisplayLang,
        };

        seriesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            console.log("JUST THE SERIES IN DIFFERENT LANG", data);

            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                    seriesname: data.seriesname,
                  };
                }
              });
            });

            resolve(this.continueWatchDataTemp);

            // console.log("THE REPLACEMENT ==", this.continueWatchData);
            // this.constructSlider();
          });
        });
      }

      if (moviesObjIds && moviesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + moviesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          objecttype: "MOVIE",
        };

        moviesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            console.log("JUST THE MOVIES IN DIFFERENT LANG", data);

            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                  };
                }
              });
            });

            resolve(this.continueWatchDataTemp);
          });
        });
      }

      Promise.all([seriesPromise, moviesPromise]).then((finalData) => {
        // console.log("FINAL DATA", finalData);

        // const finalArr = [...finalData];

        this.continueWatchData = finalData[0];

        // console.log("Final Arr -->", finalArr);

        if (this.continueWatchData && this.continueWatchData.length > 0) {
          this.continueWatchData = this.continueWatchData.filter((el) => {
            return el.status === "INPROGRESS";
          });

          this.continueData = this.continueWatchData.sort((a, b) => {
            return b.updatedat - a.updatedat;
          });
        }

        console.log("THE FINAL CONTINUE WATCH - SLIDER", this.continueData);

        this.showContinuePlaceHolder = false;
        this.constructSlider();
        // finalData.forEach((element) => {
        //   console.log("THE ARR", element);
        // });
      });
    },
  },
  beforeDestroy() {
    if(this.playerInstance) {
      this.playerInstance.deregisterEvent("contentended");
    }
    // eventBus.$off("player-closed");
    eventBus.$off("check-for-series-route");
    eventBus.$off("send-episode-list");
    eventBus.$off("check-new-fire-data");
  },
  components: {
    contentProgress: () =>
      import(
        /* webpackchunkname: contentprogress */ "@/components/Templates/contentProgress.vue"
      ),
    continueMerge: () =>
      import(
        /* webpackchunkname: contentmerge */ "@/components/categorySlider/continueMerge.vue"
      ),
    continueWatchingPlaceholder: () =>
      import(
        /* webpackchunkname: continueWatchingPlaceholder */ "@/components/placeholders/continueWatchingPlaceholder.vue"
      ),
  },
  mixins: [Utility, FirebaseActions],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
.container {
  .continue-placeholder {
    display: flex;
    &-card {
      width: 25%;
      height: 155px;
      margin: 5px;
      border-radius: 8px;
      background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
      overflow: hidden;
    }
  }
  .slider-header {
    display: flex;
    justify-content: space-between;
    .slider-title {
      display: flex;
      .title {
        color: #efeff4;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 1.5rem;
      }
      .seeall {
        cursor: pointer;
        color: #848486;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.75px;
        line-height: 1.5rem;
        padding: 0 5px;
        transition: 0.1s;
        &:hover {
          transform: scale(1.05);
          color: $clr-light-gd3;
        }
      }
    }
    .slider-head {
       width: 60%;
   .slider-head-btns {
        margin-top: 3px;
        .slider-controls {
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            img {
              height: 0.9rem;
              width: auto;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .continueWatch-slider-content {
    .categorised-continue-contents {
      // padding: 12px;
      .continueWatch-box {
        position: relative;
        transition: all 0.2s ease-in-out;
        &:hover {
          // transform: scale(1.1);

          .content-details {
            opacity: 1;
          }
        }
        .content-details {
          position: absolute;
          color: white;
          background-color: transparent;
          font-family: $font-regular;
          width: 100%;
          padding: 15px;
          border-radius: 8px;
          bottom: 0%;
          font-weight: 600;
          opacity: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .slider-title{
    white-space: nowrap;
  }
}
</style>
